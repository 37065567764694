import React, { useState } from 'react';
import { useEffect } from 'react';
import arrow from '../assets/arrow.png';
import { useNavigate } from 'react-router-dom';
import { client, urlFor } from '../sanity/client';
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';

const BlogPosts = () => {
  const [posts, setPosts] = useState([]);
  const [lastPost, setLastPost] = useState(0)
  const navigation = useNavigate()


  const getSanity = async () => {
    const blogQuery = lastPost ? `*[_type == "Posts"][${lastPost}..${lastPost + 1}] | order(_createdAt desc)` : `*[_type == "Posts"][0..7] | order(_createdAt desc)`
  
    const blogData = await client.fetch(blogQuery)
    const newBlogData = [...posts, ...blogData]
    setPosts(newBlogData)
    setLastPost(blogData[0]? newBlogData.length : 0)
  }
  useEffect(() => {
    getSanity();

   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Box className="blog-posts">
       <div className="return"><button style={{background: 'white', border: 'none'}} onClick={ () => navigation('/')}><img src={arrow} alt="backarrow"/></button></div>
      <Grid className="blog-posts-grid" container spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}
      direction="row"
      justifyContent=""
      alignItems="center">
        {
          posts ? posts.map((row) => (
            <Grid item key={row._id} >
              <Card sx={{ maxWidth: 400 }}>
                <CardActionArea onClick={() => navigation(`/blog/${row._id}`)}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={row.Imagem ? urlFor(row.Imagem).url() : ''}
                    alt={row.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {row.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {row.shortDescription?.substring(0, 600) + '...'}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          
          ))
          : null
        }

      </Grid>
      <div className="ver-mais-wrapper">
      {!posts? null : posts.length >= 8 && lastPost !== 0 ? <button className="ver-mais" onClick={() => getSanity()}> Load More</button> : <></> }
      </div>
      </Box>
      
  )
};

export default BlogPosts