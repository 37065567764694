import React, { useEffect, useRef, useState } from 'react';
import Card2 from './components/Card2';
import Navbar from './components/Navbar';
import noImage from './assets/no-image.svg';
import logo from './assets/logo.png';
import { client, urlFor } from './sanity/client';
import { Link } from 'react-router-dom';
import { Card, Grid, Typography, CircularProgress } from '@mui/material';
import emailjs from '@emailjs/browser';
import BasicModal from './components/BasicModal';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';

const App = () => {
  const [posts, setPosts] = useState();
  const [cards, setCards] = useState();
  const [numbers, setNumbers] = useState();
  const [services, setServices] = useState();
  const [toggleService, setToggleService] = useState('');
  const [generalData, setGeneralData] = useState([]);
  const [formSent, setFormSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const getSanity = async () => {
    const blogQuery = '*[_type == "Posts"][0..2] | order(_createdAt desc)';
    const dataQuery = `*[_type != "Posts"]`;
    const data = await client.fetch(dataQuery);
    const blogData = await client.fetch(blogQuery);
    setGeneralData(data);
    setNumbers(data.filter((d) => d._type === 'numbers'));
    setServices(data.filter((d) => d._type === 'services'));
    setCards(data.filter((d) => d._type === 'cards'));
    setPosts(blogData);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    await emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_TOKEN,
        'template_9c0hf7m',
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLICKEY
      )
      .then(
        (result) => {
          setLoading(false);
          setFormSent(true);
        },
        (error) => {
        }
      );

      ReactGA.event({
        category: "Contato",
        action: "form_enviado",
      });
  };
  useEffect(() => {
    getSanity();
  }, []);

  return (
    <div className="page">
       <Helmet>
        <title>Waetge Gouveia Advogados</title>
        <meta name="description" content={generalData?.find((data) => data.title === 'Quem Somos')?.content} />
        <meta name="keywords" content="Sociedade de advogados, Direito Privado, Direito Civil, Processo Civil, Direito Empresarial, Especialistas em Direito, Prevenção de conflitos, Resolução de conflitos, Diálogo claro e preciso, Assessoria jurídica, Boa gestão, Eficiência, Uso racional dos recursos, Excelência técnica, Reconhecimento da equipe, Resolução dinâmica e assertiva de conflitos, Advogados especializados, Gestão organizacional, Assessoria em negociações, Foco multidisciplinar" />
        <meta name="author" content="WAETGE Gouveia advogados" />
      </Helmet>
      <Navbar data={services} setter={setToggleService} />
      <div className="content" id="content">
        <div
          className="hero"
          style={{
            background: `url('${
              generalData[0]
                ? urlFor(
                    generalData.filter((d) => d.title === 'Banner')[0]?.Imagem
                  )
                    .auto('format')
                    .fit('max')
                    .width(2000)
                    .url()
                : ''
            }') center no-repeat fixed`,
          }}
        >
          <div className="overlay">
            <Typography variant="h5" component="h2">
              {generalData &&
                generalData.filter((d) => d.title === 'Banner')[0]?.content}
            </Typography>
          </div>
        </div>
        <div id="quem-somos" className="quem-somos">
          <div className="quem-somos-wrapper">
            <div className="bio">
              <h3>Quem Somos</h3>
              <p>
                {generalData?.find((data) => data.title === 'Quem Somos')?.content}
              </p>

              <a href="#contato"><button className="contact-button">Entre em contato</button></a>
            </div>
            <div className="cards">
              <Grid
                className="card-wrapper"
                container
                spacing={{ xs: 4, sm: 4, md: 2 }}
                columns={{ xs: 4, sm: 10, md: 12 }}
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                {cards?.map((row) => (
                  <Grid item key={row._id}>
                    <Card>
                      <BasicModal data={row} />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
        <div className="statistics">
          <div className="statistics-wrapper">
            <h3>Nossos Números</h3>
            <div className="statistics-cards">
              {numbers?.map((number) => (
                <Card2
                  type="stats"
                  stat={number.title}
                  statName={number.content}
                  key={number._id}
                />
              ))}
            </div>
          </div>
        </div>

        <div id="servicos" className="servicos">
          <div className="servicos-wrapper">
            <div>
              <img
                src={
                  generalData[0]
                    ? urlFor(
                        generalData.filter(
                          (d) => d.title === 'serviços-imagem'
                        )[0]?.Imagem
                      )
                        .auto('format')
                        .width(500)
                        .url()
                    : ''
                }
                width="500"
                height="auto"
                alt="Imagem representando a seção de serviços"
              />
            </div>

            <div className="servicos-description">
              <p>Nosso Serviços</p>
              <h2>
                {toggleService ? services?.find((data) => data.title === toggleService)?.title : services && services[0].title}
              </h2>
              <div className="tabs">
                {services?.map((data, i, arr) => {
                  return (
                    <button
                      key={data._id}
                      className={toggleService && toggleService === data.title ? 'active' : !toggleService && data.title === arr[0].title ? 'active' : null }
                      onClick={() => setToggleService(data.title)}
                    >
                      {data.title}
                    </button>
                  )
                } )}
              </div>
              <div className="tab-field">
                {toggleService ? services?.find((data) => data.title === toggleService)?.content :  services && services[0]?.content}
              </div>
            </div>
          </div>
        </div>

        <div id="contato" className="contato">
          <img
            src={
              generalData[0]
                ? urlFor(
                    generalData.filter((d) => d.title === 'contato-imagem')[0]
                      ?.Imagem
                  )
                    .auto('format')
                    .width(800)
                    .url()
                : ''
            }
            width="500"
            height="auto"
            alt="Imagem representando a seção de contatos"
          />
          <div className="form-wrapper">
            <p>Conte conosco</p>
            <h1>Entre em contato</h1>
            {formSent && (
              <Typography
                variant="h5"
                component="h2"
                style={{ color: 'white', marginLeft: 50 }}
              >
                Obrigado por entrar em contato!
              </Typography>
            )}
            {loading && !formSent && (
              <CircularProgress
                style={{
                  alignSelf: 'center',
                  top: '25%',
                  bottom: '25%',
                  position: 'relative',
                }}
              />
            )}
            {!formSent && (
              <form ref={form} onSubmit={sendEmail}>
                <input
                  type="text"
                  name="user_name"
                  placeholder="Nome"
                  required
                />
                <input
                  type="email"
                  name="user_email"
                  placeholder="Email"
                  required
                />
                <input
                  type="tel"
                  name="contact_number"
                  placeholder="(xx) xxxxx-xxxx"
                />
                <input type="text" placeholder="Assunto" />
                <textarea name="message" placeholder="Mensagem" required />
                <button type="submit">Enviar</button>
              </form>
            )}
          </div>
        </div>

        <div id="blog" className="blog">
          <p>Nosso blog</p>
          <h1>Postagens recentes</h1>
          <div className="blog-posts">
            {posts?.map((post) => (
              <Card2
                key={post._id}
                type="blog"
                title={post.title}
                thumb={
                  post.Imagem
                    ? urlFor(post.Imagem).auto('format').url()
                    : noImage
                }
                id={post._id}
                date={new Date(post.createdAt).toLocaleDateString()}
                link={`/blog/${post._id}`}
                description={post.shortDescription?.substring(0, 400) + '...'}
              />
            ))}
          </div>

          <Link to="/blog">
            <button className="ver-todos">Ver todos</button>
          </Link>
        </div>
        <div className="footer">
          <div className="logo">
            <img src={logo} alt="logo" width="150" />
          </div>
          <div className="contato">
            <h2>Entre em contato</h2>
            <p>{generalData?.find((d) => d.title === 'Endereço')?.content}</p>
            <a
              style={{ textDecoration: 'none', color: 'white' }}
              href={`tel: ${
                generalData?.find((d) => d.title === 'Telefone')?.content
              }`}
            >
              {generalData?.find((d) => d.title === 'Telefone')?.content}
            </a>
            <a
              style={{ textDecoration: 'none', color: 'white' }}
              href={`mailto: ${
                generalData?.find((d) => d.title === 'Email')?.content
              }`}
            >
              {generalData?.find((d) => d.title === 'Email')?.content}
            </a>
          </div>
          <div className="horario">
            <h2>Horários</h2>
            <p>
              {
                generalData
                  ?.find((d) => d.title === 'Horários')
                  ?.content.split(',')[0]
              }
            </p>
            <p>
              {
                generalData
                  ?.find((d) => d.title === 'Horários')
                  ?.content.split(',')[1]
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
