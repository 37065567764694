import React from 'react';
import { Link } from 'react-router-dom';

const Card2 = (props) => {

  return props.type === 'blog' ? (
    <div className="blog-container">
      <h4>{props.title}</h4>
      <div className="thumb">
        <img src={props.thumb} alt={props.title}/>
      </div>
      <div className="blog-bio">
        <p>{props.description}</p>
      </div>
      <Link to={props.link}><button className="ver-mais">Ver mais</button></Link>
    </div>
  ) : props.type === 'card' ? (
    <div className="card-container">
      <div className="card">
        <div className="avatar">
          <img src={props.avatar} alt={props.name}/>
        </div>
        <div className="card-bio">
          <h4>{props.name}</h4>
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  ) : props.type === 'stats' ? (
    <div className="stats-container">
      <h1>{props.stat}</h1>
      <p>{props.statName}</p>
    </div>
  ) : (
    <></>
  );
};

export default Card2;
