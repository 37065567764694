import React, { useEffect, useState } from 'react';
import arrow from '../assets/arrow.png';
import { useNavigate } from 'react-router-dom';
import noImage from '../assets/no-image.svg'
import { client, urlFor } from '../sanity/client';
import { PortableText } from '@portabletext/react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';

const Blog = () => {
  const [post, setPost] = useState({});
  const navigation = useNavigate()

  const getBlogPost = async () => {
    const blogQuery = `*[_id == "${window.location.pathname.replace('/blog/', '')}"]`;
    const blogData = await client.fetch(blogQuery)
    setPost(blogData[0])
  }
  useEffect(() => {
    getBlogPost()
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: post?.title });
    //eslint-disable-next-line
  }, [])
  return (
    <div className="page-blog">
      <Helmet>
        <title>Waetge Gouveia Advogados - {`${post?.title && post.title}`}</title>
        <meta name="description" content={post?.shortDescription} />
        <meta name="keywords" content={post?.keywords?.shift().replaceAll(';',',')} />
        <meta name="author" content="WAETGE Gouveia advogados" />
        <meta property="og:title" content={post?.title} />
        <meta property="og:description" content={post?.shortDescription} />
        <meta property="og:image" content={post.Imagem ? urlFor(post?.Imagem).url() : noImage} />
        <meta name="twitter:title" content={post?.title} />
        <meta name="twitter:description" content={post?.shortDescription} />
        <meta name="twitter:image"  content={post.Imagem ? urlFor(post?.Imagem).url() : noImage} />
      </Helmet>
      <div className="content-blog">
        <div className="return"><button style={{ background: 'white', border: 'none' }} onClick={() => navigation(-1)}><img src={arrow} alt="backarrow" /></button></div>
        <div className="thumb">
          <img src={post.Imagem ? urlFor(post.Imagem).url() : noImage} alt="Thumb do post" />
        </div>
        <div className="blog-content">
          {post && (
            <>
              <h1>{post.title}</h1>
              <p> {new Date(post._createdAt).toLocaleDateString()}</p>

              <article>
                <PortableText value={post.content} />
              </article>
            </>)}
          <div className="publisher">
            <div className="publisher-bio">
              <h4>{''}</h4>
              <p>WAETGE Gouveia advogados</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
