import React, { useState } from 'react';
import logo from '../assets/logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, List, ListItem } from '@mui/material';

const Navbar = ({data, setter}) => {
  const [toggle, setToggle] = useState(false);
  const [dropdown, setDropdown] = useState('none')

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setToggle(open);
  };

  const list = () => (
    <Box
      sx={{
        width: 250,
        background: '#f4f4ed',
        height: '100%',
        color: '#60afff',
        fontSize: 16,
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {[
          { content: 'Início', value: '' },
          { content: 'Quem somos', value: 'quem-somos' },
          { content: 'Serviços', value: 'servicos' },
          { content: 'Contato', value: 'contato' },
          { content: 'Blog', value: 'blog' },
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <a className="nav-toggle-buttons" href={`#${text.value}`}>
              {text.content}
            </a>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className="navbar">
      <div className="logo">
        <img src={logo} alt="logo" width="150" />
      </div>
      <div className="menu-toggle">
        <MenuIcon fontSize="large" onClick={toggleDrawer(!toggle)} />
        <Drawer anchor="right" open={toggle} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </div>

      <div className="menu-items">
        <a href="#content">Início</a>
        <a href="#quem-somos">Quem somos</a>
        <ul className="servicos-menu" onMouseEnter={() => setDropdown('')} onMouseLeave={() => setDropdown('none')}>
          <a href="#servicos">Serviços</a> 
          <li className="dropdown" style={{display: dropdown}}>
            {data && data.map((service) => (
               <a key={service.id} href="#servicos" onClick={() => setter(service.title)}>{service.title}</a>
            ))}
          </li>
        </ul>
        <a href="#contato">Contato</a>
        <a href="#blog">Blog</a>
      </div>
    </div>
  );
};

export default Navbar;
