import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import Blog from './components/Blog';
import BlogPosts from './components/BlogPosts';
import ReactGA from 'react-ga4';

ReactGA.initialize("G-60PRV923NJ");


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />} />
        <Route path="/blog" element={<BlogPosts />} />
        <Route path="/blog/:id" element={<Blog />} />
      </Routes>
    </BrowserRouter>

);
