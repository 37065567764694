import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { CardActionArea, CardContent, CardMedia } from '@mui/material';
import { urlFor } from '../sanity/client';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { PortableText } from '@portabletext/react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  margin: 0,
  padding: 2
};

export default function BasicModal({data}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const img = data.Imagem ? urlFor(data.Imagem).auto('format').fit('fill').height(400).url() : ''


  return (
    <div>
      <>
        <CardActionArea onClick={handleOpen}>
          <CardMedia
            component="img"
            height="200"
            image={img ? img : ''}
            alt={data.name}
            sx={{borderRadius: 0}}
          />
          <CardContent style={{height: 150}}>
            <Typography gutterBottom sx={{fontSize: 16, fontWeight: 700 }} component="div">
              {data.name}
            </Typography>
            <Typography sx={{fontSize: 14}}color="text.secondary">
              {data.shortBbio}
            </Typography>
          </CardContent>
          <div className="icon-wrapper">
            <ExpandCircleDownIcon fontSize="small" />
          </div>
        </CardActionArea>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <img src={img ? img : ''} width="300" height="auto" alt={data.name}/>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {data.name}
            </Typography>
              <PortableText value={data.bio}/>
          </Box>
        </Modal>
      </>
    </div>
  );
}
